import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";

export const useMedicationsSearch = (search = "", isManual = false) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["medications", search],
    queryFn: async () => {
      if (!search) return { data: [] };
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/drugsfile/search/${search}`
      );
      return response.data;
    },
    enabled: !isManual && search.length > 2,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    debounceTime: 300,
  });
};
