import { useState } from "react";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import style from "./AddReferralForm.module.css";

import Modal from "../helpers/Modal/Modal";
import { FormField } from "../form/FormField/FormField";
import CustomCheckbox from "../helpers/CustomCheckbox/CustomCheckbox";

const formSchema = z.object({
  saveAsTemplate: z.boolean(),
  referral: z
    .string()
    .min(1, { message: "Please provide content for referral." }),
  title: z.string().min(1, { message: "Please provide referral title." }),
  type: z.string().min(1, { message: "Please select referral type." }),
  notifyAt: z.string(),
});

export const AddReferralForm = ({ patient, isDisabled, buttonClassName }) => {
  const today = dayjs(new Date().setMinutes(0));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      saveAsTemplate: false,
      refferal: "",
      title: "",
      type: "type-1",
      notifyAt: moment(new Date(today)).format("DD-MM-YYYY"),
    },
  });

  const handleFormSubmit = (values) => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className={`${style.openModalButton} ${buttonClassName}`}
        type="button"
        disabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
      >
        Add Referral
      </button>
      {isModalOpen && (
        <Modal
          title="Add referral"
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <FormProvider {...form}>
            <form
              className={style.addReferralForm}
              onSubmit={form.handleSubmit(handleFormSubmit)}
            >
              <div className={style.templateSection}>
                <p className={style.addReferralFormLabel}>
                  Templates (optional)
                </p>
                <div className={style.inputGroupGrid}>
                  <button className={style.openModalButton}>
                    Select template
                  </button>
                </div>
              </div>
              <div className={style.inputGroupGrid}>
                <FormField name="type">
                  <label className={style.addReferralFormLabel}>Type</label>
                  <select
                    as="select"
                    className="form-select form-control custom-select"
                    {...form.register("type")}
                  >
                    <option value="type-1">type 1</option>
                    <option value="type-2">type 2</option>
                  </select>
                </FormField>
                <div>
                  <label className={style.addReferralFormLabel}>Patient</label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      patient &&
                      `${patient.firstName} ${patient.surname} (DOB - ${moment(
                        new Date(patient.dateOfBirth)
                      ).format("DD/MM/YYYY")})`
                    }
                    disabled
                  />
                </div>
              </div>
              <FormField name="title">
                <label className={style.addReferralFormLabel}>Title</label>
                <div className={style.cmInput}>
                  <input className="form-control" {...form.register("title")} />
                </div>
              </FormField>
              <FormField name="referral">
                <label className={style.addReferralFormLabel}>Referral</label>
                <textarea
                  className="form-control textareaform-control"
                  style={{ minHeight: "280px" }}
                  {...form.register("referral")}
                />
              </FormField>
              <div className={style.notifySection}>
                <label className="form-check form-switch onoff_area">
                  <div className={style.switchWrapper}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      value={notify}
                      onChange={(e) => setNotify(e.target.checked)}
                    />
                    <span>Add task and notify</span>
                  </div>
                </label>
                {notify && (
                  <FormField name="notifyAt">
                    <div className={style.addPregnancyDatePickerWrapper}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD-MM-YYYY"
                          name="notifyAt"
                          defaultValue={today}
                          sx={{
                            width: "100%",
                            height: "36px",
                          }}
                          onChange={(newValue) => {
                            form.setValue(
                              "notifyAt",
                              moment(new Date(dayjs(newValue))).format(
                                "DD-MM-YYYY"
                              )
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </FormField>
                )}
              </div>
              <div className={style.bottomSection}>
                <label className={style.formCheckboxLabel}>
                  <CustomCheckbox {...form.register("saveAsTemplate")} />
                  Save as template
                </label>
                <div>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className={style.openModalButton}
                    type="button"
                  >
                    Save for later
                  </button>
                  <button className={style.sendButton} type="submit">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </Modal>
      )}
    </>
  );
};
