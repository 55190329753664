import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import sortIcon from "../../../../../../../assets/images/sort.svg";
import { ArrowDownToLine, ArrowUpFromLine } from "lucide-react";
import styles from "./Table.module.css";

export const SortByButton = ({ selectedItem, setSelectedItem, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="sortBy"
        aria-controls={open ? "sortBy" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.sortByButton}
        disabled={disabled}
      >
        Sort by
        <img src={sortIcon} alt="sort" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={styles.menuItemWrapper}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setSelectedItem("newest-first");
          }}
          className={`${styles.menuItem} ${
            selectedItem === "newest-first" &&
            styles.sortByButtonMenuOptionActive
          }`}
        >
          <button>
            <ArrowDownToLine size={20} />
            Newest First
          </button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setSelectedItem("oldest-first");
          }}
          className={`${styles.menuItem} ${
            selectedItem === "oldest-first" &&
            styles.sortByButtonMenuOptionActive
          }`}
        >
          <button>
            <ArrowUpFromLine size={20} />
            Oldest First
          </button>
        </MenuItem>
      </Menu>
    </div>
  );
};
