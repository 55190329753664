import { Table } from "./Table/Table";
import styles from "../../PatientOverviewModal.module.css";
import { useMedicalHistory } from "../../../../../../hooks";

export const MedicalHistory = ({ emptyState }) => {
  const patientId = localStorage.getItem("kinesin-patientId");

  const { data: medicalHistory, isLoading } = useMedicalHistory(patientId);

  return (
    <div className={styles.medicalHistoryWrapper}>
      <Table
        items={(medicalHistory || []).map((item) => ({
          dateRecorded: item.recordedDate,
          idc: `${item.diseaseCode || "Unknown code"}: ${item.medicalDetails}`,
        }))}
        tableName="medical-history"
        isLoading={isLoading}
        emptyState={emptyState}
      />
    </div>
  );
};
