import React from "react";
import s from "./AcuteMedication.module.css";
import { Toaster } from "react-hot-toast";
import { useAcuteMedications } from "../../../../../../hooks/useAcuteMedications.js";
import { DrugsTable } from "../DrugsTable/DrugsTable.jsx";

const AcuteMedication = () => {
  const patientId = localStorage.getItem("kinesin-patientId");

  const { data: medications, isLoading } = useAcuteMedications(patientId);

  return (
    <div className={s.longTermWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={s.topWrapper}>
        <h3 className={s.title}>Acute medications</h3>
      </div>

      <DrugsTable items={medications} isLoading={isLoading} tableName="acute" />
    </div>
  );
};

export default AcuteMedication;
