import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import debounce from "lodash/debounce";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export const useUpdateConsultationNotes = (consultationId) => {
  const queryClient = useQueryClient();
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const mutation = useMutation({
    mutationFn: async (notes) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/consultationnote`,
        {
          consultationId: Number(consultationId),
          note: notes,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["consultationNote", consultationId]);
    },
    onError: (error) => {
      console.error(`Error saving notes: ${error.message}`);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMutate = useCallback(
    debounce(
      (notes) => {
        if (!mutation.isPending) {
          mutation.mutate(notes);
        }
      },
      2000,
      { maxWait: 3000 }
    ),
    [mutation.isPending]
  );

  return {
    updateNotes: debouncedMutate,
    isPending: mutation.isPending,
  };
};
