import { useState } from "react";
import moment from "moment";
import { Toaster } from "react-hot-toast";
import { Loader2 } from "lucide-react";
import { Image } from "react-bootstrap";

import srcicon from "../../../../../../assets/images/srcicon.svg";
import EmptyState from "../../../../../../assets/images/medications-empty-state.svg";
import styles from "./DrugsTable.module.css";
import { SortByButton } from "./SortByButton";

export const DrugsTable = ({ items, tableName, isLoading, emptyTitle }) => {
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("newest-first");

  const sortedAndFilteredItems =
    items
      ?.map((item) => ({
        ...item,
        medications:
          item.medicines?.map((medicine) => medicine.tradeName).join(", ") ||
          "N/A",
      }))
      .sort((a, b) => {
        if (sorting === "newest-first") {
          return (
            moment(
              b.datePrescribed?.join(","),
              "YYYY,MM,DD,HH,mm,ss"
            ).valueOf() -
            moment(a.datePrescribed?.join(","), "YYYY,MM,DD,HH,mm,ss").valueOf()
          );
        }
        return (
          moment(a.datePrescribed?.join(","), "YYYY,MM,DD,HH,mm,ss").valueOf() -
          moment(b.datePrescribed?.join(","), "YYYY,MM,DD,HH,mm,ss").valueOf()
        );
      })
      .filter((item) =>
        item.medications.toLowerCase().includes(search.toLowerCase())
      ) || [];

  if (items?.length === 0) {
    return (
      <div className={styles.emptyState}>
        <Image src={EmptyState} alt="category" />
        <div>
          <p className={styles.emptyStateTitle}>
            {emptyTitle || "No Acute prescriptions"}
          </p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "Once the patient receives a prescription, it will appear here."}
          </p>
        </div>
      </div>
    );
  }

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState2}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle2}>Loading data...</p>
          <p className={styles.emptyStateText2}>
            Please wait while we fetch the medical history.
          </p>
        </div>
      );
    }

    if (sortedAndFilteredItems.length === 0) {
      return (
        <div className={styles.emptyState2}>
          <p className={styles.emptyStateTitle2}>No items found</p>
          <p className={styles.emptyStateText2}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "There are no items to display at this time."}
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.tableItems}>
        {sortedAndFilteredItems.map((item) => (
          <li className={styles.tableItem} key={item.id}>
            <p aria-describedby={`${tableName}-table-date-prescribed`}>
              {item.dateRecorded
                ? moment(item.dateRecorded).format("DD/MM/YYYY")
                : "N/A"}
            </p>
            <p aria-describedby={`${tableName}-table-doctor`}>{item.idc}</p>
            <p aria-describedby={`${tableName}-table-drugs-name`}>
              {item.drugsName}
            </p>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                disabled={isLoading}
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton
              selectedItem={sorting}
              setSelectedItem={setSorting}
              disabled={isLoading}
            />
          </div>
          <div className={styles.tableHeader}>
            <p id={`${tableName}-table-date-prescribed`}>Drug name</p>
            <p id={`${tableName}-table-doctor`}>Dose</p>
            <p id={`${tableName}-table-drugs-name`}>Date prescribed</p>
          </div>
          {renderTableContent()}
        </div>
      </div>
    </>
  );
};
