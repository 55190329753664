import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

export const useCreateTask = () => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userData) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(`${tenantVal}/task`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success("New Task created successfully");
      queryClient.invalidateQueries(["tasks"]);
    },
    onError: (error) => {
      toast.error(`Error creating task: ${error.message}`);
    },
  });
};
