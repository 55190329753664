import { useState } from "react";

import srcicon from "../../../../../assets/images/srcicon.svg";

import styles from "./VaccinesTable.module.css";
import { SortByButton } from "./SortByButton";
import { VaccinesTableItem } from "./VaccinesTableItem";
import { Toaster } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { fetchImmunizations } from "../utils/fetchImmunizations";
import { Loader2 } from "lucide-react";

export const VaccinesTable = ({ patientId, tenantVal, seletedVaccineType }) => {
  const [sorting, setSorting] = useState("newest-first");
  const [search, setSearch] = useState("");

  const { data: data1, isLoading } = useQuery({
    queryKey: ["vaccines", tenantVal, seletedVaccineType],
    queryFn: () => fetchImmunizations(tenantVal, seletedVaccineType),
  });

  const data = [];

  const sortedAndFilteredItems = data?.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading data...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch the medical history.
          </p>
        </div>
      );
    }

    if (sortedAndFilteredItems.length === 0) {
      return (
        <div className={styles.emptyState}>
          <p className={styles.emptyStateTitle}>No items found</p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "There are no items to display at this time."}
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.tableItems}>
        {data?.map((item) => (
          <VaccinesTableItem
            key={item.id}
            item={item}
            patientId={patientId}
            tenantVal={tenantVal}
            isMultiDose={seletedVaccineType === "multi-dose"}
          />
        ))}
      </ul>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton selectedItem={sorting} setSelectedItem={setSorting} />
          </div>
          <div className={styles.tableHeader}>
            <p id="report-table-type-of-vaccine">Type of Vaccine</p>
            <p id="report-table-date-given">Date Given</p>
            <p id="report-table-type">Type</p>
            <p id="report-table-batch-no">Batch No.</p>
            <p id="report-table-expiry-date">Expiry Date</p>
          </div>
          {renderTableContent()}
        </div>
      </div>
    </>
  );
};
