import React, { useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import s from "./PatientConsult.module.css";
import ConsultationEdit from "./components/ConsultationEdit/ConsultationEdit.jsx";
import PatientDetailCardWithOverview from "../../../../components/PatientDetailCardWithOverview/PatientDetailCardWithOverview.jsx";
import { ConsultationBox } from "./components/ConsultationBox/ConsultationBox.jsx";

import {
  usePatientData,
  useStaffInfo,
  useActiveConsultation,
} from "../../../../hooks";

const PatientConsult = () => {
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const { data: staffInfo } = useStaffInfo();
  const { data: patient } = usePatientData();
  const { data: activeConsultation } = useActiveConsultation(
    patient?.activeConsultationId
  );

  const staffName = staffInfo?.staffMember
    ? `${staffInfo.staffMember.firstName} ${staffInfo.staffMember.lastName}`
    : "";

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstRowWrapper}>
            <div className={s.firstColumnWrapper}>
              <PatientDetailCardWithOverview />
            </div>
            <div className={s.secondColumnWrapper}>
              <div className={s.secondColumnSubWrapper}>
                <ConsultationBox
                  patient={patient}
                  staffName={staffName}
                  consultation={activeConsultation}
                  saveAsTemplate={saveAsTemplate}
                  setSaveAsTemplate={setSaveAsTemplate}
                />
              </div>
            </div>
          </div>
          <div className={s.secondRowWrapper}>
            <div className={s.firstColumnWrapper}>
              <ConsultationEdit
                patient={patient}
                consultation={activeConsultation}
                isDisabled={!activeConsultation}
                staffInfo={staffInfo}
                saveAsTemplate={saveAsTemplate}
                setSaveAsTemplate={setSaveAsTemplate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientConsult;
