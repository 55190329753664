import { useState } from "react";
import { useNoteTemplates } from "../../../../../../../hooks/useNoteTemplates";
import styles from "./ChooseTemplateModal.module.css";
import { Loader2 } from "lucide-react";
import Modal from "../../../../../../../components/helpers/Modal/Modal";

export const ChooseTemplateModal = ({ onSelect, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { data: templates, isLoading } = useNoteTemplates();

  const filteredTemplates = templates?.filter((template) =>
    template.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (template) => {
    onSelect?.(template.note);
    setIsOpen(false);
    setSearch("");
  };

  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={styles.openModalButton}
        disabled={isDisabled}
      >
        Select Template
      </button>

      {isOpen && (
        <Modal
          title="Choose template"
          width="600px"
          onClose={() => setIsOpen(false)}
        >
          <div className={styles.searchWrapper}>
            <input
              type="text"
              placeholder="Search templates..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className={styles.searchInput}
            />
          </div>
          <div className={styles.templateListWrapper}>
            <div className={styles.templateList}>
              {isLoading ? (
                <div className={styles.loading}>
                  <Loader2 className={styles.spinner} size={24} />
                  <p>Loading templates...</p>
                </div>
              ) : filteredTemplates?.length ? (
                filteredTemplates.map((template) => (
                  <div key={template.id} className={styles.templateItem}>
                    <div className={styles.templateInfo}>
                      <h4>{template.name}</h4>
                      <p className={styles.templateDate}>
                        {template.updated || template.created || "N/A"}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleSelect(template)}
                      className={styles.button}
                    >
                      Use Template
                    </button>
                  </div>
                ))
              ) : (
                <div className={styles.noResults}>
                  <p>No templates found</p>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
