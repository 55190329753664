import { z } from "zod";

export const medicineSchema = z
  .object({
    dosage: z.string().min(1, { message: "Please provide dosage." }),
    form: z.string().min(1, { message: "Please provide form." }),
    startDate: z.string().min(1, { message: "Please provide start date." }),
    route: z.string().min(1, { message: "Please provide route." }),
    duration: z.string().optional(),
    durationFormat: z.string().optional(),
    usageType: z.string().min(1, { message: "Please provide usage type." }),
    repeat: z.string().optional(),
    isManual: z.boolean(),
  })
  .refine(
    (data) => {
      if (data.isManual) {
        return data.tradeName && data.manufacturer && data.packSize;
      }
      return data.medicineId;
    },
    {
      message: "Please provide required medication details",
      path: ["medicineId"],
    }
  )
  .and(
    z.union([
      z.object({
        isManual: z.literal(true),
        tradeName: z.string().min(1, { message: "Please provide trade name." }),
        manufacturer: z
          .string()
          .min(1, { message: "Please provide manufacturer." }),
        packSize: z.string().min(1, { message: "Please provide pack size." }),
        medicineId: z.string().optional(),
      }),
      z.object({
        isManual: z.literal(false),
        medicineId: z
          .string()
          .min(1, { message: "Please select a medication." }),
        tradeName: z.string().optional(),
        manufacturer: z.string().optional(),
        packSize: z.string().optional(),
      }),
    ])
  );
