import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

export const usePracticeServices = () => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["practiceServices", tenantVal],
    queryFn: async () => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(`${tenantVal}/practiceservice`);
      return response.data;
    },
    enabled: !!tenantVal,
    onError: (error) => {
      toast.error(`Error fetching practice services: ${error.message}`);
    },
  });
};
