import React from "react";
import s from "./AllergiesIntolerance.module.css";

import Allergies from "./Allergies/Allergies.jsx";
import Intolerance from "./Intolerance/Intolerance.jsx";

const AlergiesIntolerance = () => {
  return (
    <div className={s.cardWrapper}>
      <Allergies />
      <Intolerance />
    </div>
  );
};

export default AlergiesIntolerance;
