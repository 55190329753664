import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-calendar/dist/Calendar.css";

import style from "./AddPregnancyForm.module.css";
import { EstimateddueDateField } from "./EstimatedDueDateField";
import CustomCheckbox from "../../../../../components/helpers/CustomCheckbox/CustomCheckbox";
import { FormField } from "../../../../../components/form/FormField/FormField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../../../../../api/axiosConfig";

const formSchema = z.object({
  gravida: z.string().min(1, { message: "Enter the number of pregnancies." }),
  para: z.string().min(1, { message: "Enter the number of births." }),
  history: z
    .string()
    .min(1, { message: "Please provide the patient's medical history." }),
  medications: z
    .string()
    .min(1, { message: "Please list the current medications." }),
  hasClinicHCGPositive: z.boolean(),
  smoker: z.boolean(),
  noFamilyHistoryOfCongenitalAbnormality: z.boolean(),
  hasHadChickenPox: z.boolean(),
  lastMenstrualPeriod: z.string({
    message: "Please enter the date of the last menstrual period.",
  }),
});

const AddPregnancyForm = ({ currentPatientId, closeModal, tenantVal }) => {
  const queryClient = useQueryClient();
  const today = dayjs(new Date().setMinutes(0));
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      gravida: "",
      para: "",
      history: "",
      medications: "",
      hasClinicHCGPositive: false,
      smoker: false,
      noFamilyHistoryOfCongenitalAbnormality: false,
      hasHadChickenPox: false,
      lastMenstrualPeriod: moment(new Date(today)).format("DD-MM-YYYY"),
    },
  });

  const { mutate: addPregnancy, isPending } = useMutation({
    mutationFn: async (values) => {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.post(`${tenantVal}/pregnancy`, {
        patientId: currentPatientId,
        lastMenstrualPeriod: moment(
          values.lastMenstrualPeriod,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD"),
        estimatedDueDate: moment(values.lastMenstrualPeriod, "DD-MM-YYYY")
          .add(280, "days")
          .format("YYYY-MM-DD"),
        gravida: values.gravida,
        para: values.para,
        hasChickenPox: values.hasHadChickenPox,
        familyHistoryCongentialAbnormality:
          values.noFamilyHistoryOfCongenitalAbnormality,
        smoker: values.smoker,
        history: values.history,
        medication: values.medication,
        confirmHcgPositive: values.hasClinicHCGPositive,
      });
    },
    onSuccess: () => {
      toast("Pregnancy has been added.");
      form.reset();
      closeModal();
      queryClient.invalidateQueries(["patient", "pregnancies"]);
    },
    onError: () => {
      toast.error("Oups, something went wrong!");
    },
  });

  const handleFormSubmit = async (values) => {
    addPregnancy(values);
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <FormProvider {...form}>
        <form
          className={style.addPregnancyForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <div className={style.inputGroupGrid}>
            <FormField name="lastMenstrualPeriod">
              <div className={style.addPregnancyDatePickerWrapper}>
                <label className={style.pregnancyFormLabel}>
                  Last Menstrual Period
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    name="lastMenstrualPeriod"
                    defaultValue={today}
                    sx={{
                      width: "100%",
                      height: "36px",
                    }}
                    onChange={(newValue) => {
                      form.setValue(
                        "lastMenstrualPeriod",
                        moment(new Date(dayjs(newValue))).format("DD-MM-YYYY")
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </FormField>
            <EstimateddueDateField form={form} />
          </div>
          <FormField name="history">
            <label className={style.pregnancyFormLabel}>History</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("history")}
            />
          </FormField>
          <FormField name="medications">
            <label className={style.pregnancyFormLabel}>Medications</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("medications")}
            />
          </FormField>
          <fieldset className={style.medsCheckboxGroup}>
            <div className={style.checkboxWrapper}>
              <label className={style.pregnancyFormCheckboxLabel}>
                <CustomCheckbox {...form.register("smoker")} />
                Smoker
              </label>
              <label className={style.pregnancyFormCheckboxLabel}>
                <CustomCheckbox
                  {...form.register("noFamilyHistoryOfCongenitalAbnormality")}
                />
                No family history of congenital abnormality
              </label>
              <label className={style.pregnancyFormCheckboxLabel}>
                <CustomCheckbox {...form.register("hasHadChickenPox")} />
                Has had chicken pox
              </label>
            </div>
          </fieldset>
          <div className={style.bottomSectionInputGrid}>
            <fieldset>
              <legend className={style.checkboxSectionLegend}>Exam</legend>
              <div className={style.checkboxWrapper}>
                <label className={style.pregnancyFormCheckboxLabel}>
                  <CustomCheckbox {...form.register("hasClinicHCGPositive")} />
                  Clinic HCG positive
                </label>
              </div>
            </fieldset>
            <FormField name="gravida">
              <label className={style.pregnancyFormLabel}>Gravida</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                {...form.register("gravida")}
              />
            </FormField>
            <FormField name="para">
              <label className={style.pregnancyFormLabel}>Para</label>
              <input
                inputMode="numeric"
                type="text"
                className="form-control"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                {...form.register("para")}
              />
            </FormField>
          </div>
          <div className={style.buttonRow}>
            <button
              className={style.cancelButton}
              onClick={closeModal}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={style.addPregnancyButton}
              disabled={isPending}
            >
              Add
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddPregnancyForm;
