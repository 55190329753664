import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

export const useAddConsultationService = (consultationId) => {
  const queryClient = useQueryClient();

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (serviceId) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/consultationservice`,
        {
          consultationId: Number(consultationId),
          serviceId: Number(serviceId),
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Service added successfully");
      queryClient.invalidateQueries(["consultationServices", consultationId]);
    },
    onError: (error) => {
      toast.error(`Error adding service: ${error.message}`);
    },
  });
};
