import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";

export const useConsultationServices = (consultationId) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["consultationServices", consultationId],
    queryFn: async () => {
      if (!consultationId) return null;

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/consultationservice/getbyconsultationid/${consultationId}`
      );
      return response.data;
    },
    enabled: !!consultationId,
    onError: (error) => {
      console.error(`Error fetching consultation payments: ${error.message}`);
    },
  });
};
