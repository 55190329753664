import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

export const usePatientConsultations = (patientId) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["consultations", patientId],
    queryFn: async () => {
      if (!patientId) return null;
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/consultationnote/getbypatientid/${patientId}`
      );
      return response.data;
    },
    enabled: !!patientId,
    onError: (error) => {
      toast.error(`Error fetching consultations: ${error.message}`);
    },
  });
};
