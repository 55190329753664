import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";

export const useStaffInfo = () => {
  return useQuery({
    queryKey: ["staffInfo"],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance(true);
      const response = await axiosInstance.get(`/api/v1/whoami`);
      return response.data;
    },
    onError: (error) => {
      toast.error(`Error fetching staff info: ${error.message}`);
    },
  });
};
