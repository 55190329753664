import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-calendar/dist/Calendar.css";

import style from "./AddVaccineForm.module.css";
import { FormField } from "../../../../../components/form/FormField/FormField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../../../../../api/axiosConfig";
import s from "../../../PatientDetailGp/components/EditModalForm/EditModalForm.module.css";

const formSchema = z.object({
  dateGiven: z.string().min(1, { message: "Enter the given date." }),
  type: z.string().min(1, { message: "You must choose type." }),
  batchNo: z.string().min(1, { message: "Enter the batch no." }),
  administrationMethod: z
    .string()
    .min(1, { message: "You must choose administration method." }),
  manufacturer: z
    .string()
    .min(1, { message: "You must choose administration manufacturer." }),
  expiryDate: z.string().min(1, { message: "Enter the expiry date." }),
  siteGiven: z.string().min(1, { message: "You must choose site given." }),
  dose: z.string().min(1, { message: "Enter the dose." }),
});

export const AddVaccineForm = ({ currentPatientId, closeModal, tenantVal }) => {
  const queryClient = useQueryClient();
  const today = dayjs(new Date().setMinutes(0));
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: "",
      batchNo: "",
      administrationMethod: "",
      manufacturer: "",
      expiryDate: "",
      siteGiven: "",
      dose: "",
      reaction: "Unknown",
      reactionDate: "",
      notes: "",
      patientId: currentPatientId,
    },
  });

  const { mutate: addImmunisation, isPending } = useMutation({
    mutationFn: async (values) => {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.post(`${tenantVal}/patientimmunisation`, {
        vaccineName: values.type,
        batchNo: values.batchNo,
        administrationMethod:values.administrationMethod,
        manufacturer: values.manufacturer,
        expiryDate: values.expiryDate,
        siteGiven: values.siteGiven,
        doseMl: values.dose,
        reaction: values.reaction,
        reactionDate: values.reactionDate,
        notes: values.notes,
        patientId: currentPatientId
      });
    },
    onSuccess: () => {
      toast("Vaccine has been added.");
      form.reset();
      closeModal();
      queryClient.invalidateQueries(["vaccines"]);
    },
    onError: () => {
      toast.error("Oups, something went wrong!");
    },
  });

  const handleFormSubmit = async (values) => {
    addImmunisation(values);
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <FormProvider {...form}>
        <form
            className={style.addPregnancyForm}
            onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <div className={style.inputGroupGrid}>
            <FormField name="type">
              <label className={style.pregnancyFormLabel}>Type</label>
              <select
                  as="select"
                  {...form.register("type")}
                  className="form-select form-control custom-select"
              >
                <option value="Influenza">Influenza</option>
                <option value="Pneumococcol">Pneumococcol</option>
                <option value="6:1">6:1</option>
                <option value="MMR">MMR</option>
                <option value="PCV">PCV</option>
                <option value="Men B">Men B</option>
                <option value="Men C">Men C</option>
                <option value="Hib/ MenC">Hib/ MenC</option>
                <option value="Covid-19 Primary/ Secondary">
                  Covid-19 Primary/ Secondary
                </option>
                <option value="Covid-19 Booster">Covid-19 Booster</option>
                <option value="Covid-19 Immunocompromised">
                  Covid-19 Immunocompromised
                </option>
                <option value="MMRV">MMRV</option>
                <option value="MenACWY">MenACWY</option>
                <option value="Rotavirus oral vaccine">
                  Rotavirus oral vaccine
                </option>
              </select>
            </FormField>
            <FormField name="batchNo">
              <label className={style.pregnancyFormLabel}>Batch No</label>
              <input
                  type="text"
                  className="form-control"
                  {...form.register("batchNo")}
              />
            </FormField>
          </div>
          <div className={style.inputGroupGrid}>
            <FormField name="administrationMethod">
              <label className={style.pregnancyFormLabel}>
                Administration Method
              </label>
              <select
                  as="select"
                  {...form.register("administrationMethod")}
                  className="form-select form-control custom-select"
              >
                <option value="Intramuscular (IM)">Intramuscular (IM)</option>
                <option value="Subcutaneous (SQ)">Subcutaneous (SQ)</option>
                <option value="Oral (PO)">Oral (PO)</option>
                <option value="Intranasal">Intranasal</option>
              </select>
            </FormField>
            <FormField name="manufacturer">
              <label className={style.pregnancyFormLabel}>Manufacturer</label>
              <select
                  as="select"
                  {...form.register("manufacturer")}
                  className="form-select form-control custom-select"
              >
                <option value="Pfizer">Pfizer</option>
                <option value="BioNTech">BioNTech</option>
                <option value="Sinovac">Sinovac</option>
                <option value="Moderna">Moderna</option>
                <option value="Merck">Merck</option>
                <option value="GSK">GSK</option>
                <option value="Sanofi">Sanofi</option>
                <option value="Zhifei">Zhifei</option>
                <option value="AstraZeneca">AstraZeneca</option>
                <option value="Johnson & Johnson">Johnson & Johnson</option>
                <option value="Bavarian Nordic">Bavarian Nordic</option>
                <option value="Inovio">Inovio</option>
                <option value="Novavax">Novavax</option>
              </select>
            </FormField>
          </div>
          <div className={style.inputGroupGrid}>
            <FormField name="expiryDate">
              <div className={style.addPregnancyDatePickerWrapper}>
                <label className={style.pregnancyFormLabel}>Expiry Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      format="DD-MM-YYYY"
                      name="expiryDate"
                      defaultValue={today}
                      sx={{
                        width: "100%",
                        height: "36px",
                      }}
                      onChange={(newValue) => {
                        form.setValue(
                            "expiryDate",
                            moment(new Date(dayjs(newValue))).format("YYYY-MM-DD")
                        );
                      }}
                  />
                </LocalizationProvider>
              </div>
            </FormField>
            <FormField name="siteGiven">
              <label className={style.pregnancyFormLabel}>Site Given</label>
              <select
                  as="select"
                  className="form-select form-control custom-select"
                  {...form.register("siteGiven")}
              >
                <option value="Oral">Oral Route</option>
                <option value="Intranasal">Intranasal Route</option>
                <option value="Subcutaneous">Subcutaneous Route</option>
                <option value="Intramuscular">Intramuscular Route</option>
              </select>
            </FormField>
          </div>
          <div className={style.inputGroupGrid}>
            <FormField name="dose">
              <label className={style.pregnancyFormLabel}>Dose</label>
              <div className={style.mlInput}>
                <input
                    className="form-control"
                    {...form.register("dose")}
                    type="text"
                    inputMode="numeric"
                    onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                />
              </div>
            </FormField>
            <FormField name="type">
              <label className={style.pregnancyFormLabel}>Reaction</label>
              <select
                  as="select"
                  {...form.register("reaction")}
                  className="form-select form-control custom-select"
              >
                <option value="Unknown">Unknown</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </FormField>
          </div>
          <div className={style.inputGroupGrid}>
            <FormField name="expiryDate">
              <div className={style.addPregnancyDatePickerWrapper}>
                <label className={style.pregnancyFormLabel}>Reaction Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      format="DD-MM-YYYY"
                      name="expiryDate"
                      defaultValue={today}
                      sx={{
                        width: "100%",
                        height: "36px",
                      }}
                      onChange={(newValue) => {
                        form.setValue(
                            "reactionDate",
                            moment(new Date(dayjs(newValue))).format("YYYY-MM-DD")
                        );
                      }}
                  />
                </LocalizationProvider>
              </div>
            </FormField>
            <FormField name="notes">
              <label className={style.pregnancyFormLabel}>Notes</label>
              <textarea
                  rows="5"
                  placeholder="Enter notes"
                  className="form-control"
                  value=""
                  {...form.register("notes")}
              />
            </FormField>
          </div>

          <div className={style.buttonRow}>
                <button
                    className={style.cancelButton}
                    onClick={closeModal}
                    type="button"
                >
                  Cancel
                </button>
                <button
                    type="submit"
                    className={style.addPregnancyButton}
                    disabled={isPending}
                >
                  Add
                </button>
              </div>
        </form>
      </FormProvider>
    </div>
);
};
