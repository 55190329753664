import noConsultationsIcon from "../../../../../../assets/images/pregnancy/empty-pregnancy-consultations.svg";

import style from "./PregnancyLoader.module.css";

export const PregnancyLoader = ({ children }) => {
  return (
    <section className={style.emptyState}>
      <img className={style.element} alt="Element" src={noConsultationsIcon} />
      <div className={style.text}>
        <div className={style.div}>
          <div className={style.textWrapper}>{children || "Loading..."}</div>
          <div className={style.loader}></div>
        </div>
      </div>
    </section>
  );
};
