import { useMutation } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

export const useSaveTemplate = () => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async ({ name, note }) => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post(`${tenantVal}/notetemplate`, {
        name,
        note,
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success("Template saved successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to save template");
      throw error;
    },
  });
};
