import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import style from "./AddLetterForm.module.css";

import Modal from "../helpers/Modal/Modal";
import { FormField } from "../form/FormField/FormField";
import CustomCheckbox from "../helpers/CustomCheckbox/CustomCheckbox";
import moment from "moment";

const formSchema = z.object({
  saveAsTemplate: z.boolean(),
  letter: z.string().min(1, { message: "Please provide content for letter." }),
  title: z.string().min(1, { message: "Please provide letter title." }),
  type: z.string().min(1, { message: "Please select letter type." }),
});

export const AddLetterForm = ({ patient, isDisabled, buttonClassName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      saveAsTemplate: false,
      letter: "",
      title: "",
      type: "type-1",
    },
  });

  const handleFormSubmit = (values) => {
    console.log(values);
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className={`${style.openModalButton} ${buttonClassName}`}
        type="button"
        disabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
      >
        Add Letter
      </button>
      {isModalOpen && (
        <Modal
          title="Add Letter"
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <FormProvider {...form}>
            <form
              className={style.addLetterForm}
              onSubmit={form.handleSubmit(handleFormSubmit)}
            >
              <div className={style.templateSection}>
                <p className={style.addLetterFormLabel}>Templates (optional)</p>
                <div className={style.inputGroupGrid}>
                  <button className={style.openModalButton}>
                    Select template
                  </button>
                </div>
              </div>
              <div className={style.inputGroupGrid}>
                <FormField name="type">
                  <label className={style.addLetterFormLabel}>Type</label>
                  <select
                    as="select"
                    className="form-select form-control custom-select"
                    {...form.register("type")}
                  >
                    <option value="type-1">type 1</option>
                    <option value="type-2">type 2</option>
                  </select>
                </FormField>
                <div>
                  <label className={style.addLetterFormLabel}>Patient</label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      patient &&
                      `${patient.firstName} ${patient.surname} (DOB - ${moment(
                        new Date(patient.dateOfBirth)
                      ).format("DD/MM/YYYY")})`
                    }
                    disabled
                  />
                </div>
              </div>
              <FormField name="title">
                <label className={style.addLetterFormLabel}>Title</label>
                <div className={style.cmInput}>
                  <input className="form-control" {...form.register("title")} />
                </div>
              </FormField>
              <FormField name="letter">
                <label className={style.addLetterFormLabel}>Letter</label>
                <textarea
                  className="form-control textareaform-control"
                  style={{ minHeight: "280px" }}
                  {...form.register("letter")}
                />
              </FormField>
              <div className={style.bottomSection}>
                <label className={style.formCheckboxLabel}>
                  <CustomCheckbox {...form.register("saveAsTemplate")} />
                  Save as template
                </label>
                <div>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className={style.openModalButton}
                    type="button"
                  >
                    Save for later
                  </button>
                  <button className={style.sendButton} type="submit">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </Modal>
      )}
    </>
  );
};
