import { usePatientConsultations } from "../../../../../../hooks/usePatientConsultations";
import { Table } from "./Table/Table";
import styles from "../../PatientOverviewModal.module.css";

export const Consultations = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const { data: consultations, isLoading } = usePatientConsultations(patientId);

  return (
    <div className={styles.medicalHistoryWrapper}>
      <Table items={consultations || []} isLoading={isLoading} />
    </div>
  );
};
