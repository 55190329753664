import { useMutation } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

export const useCreatePrescription = () => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (prescriptionData) => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/patientprescription`,
        prescriptionData
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Prescription created successfully");
    },
    onError: (error) => {
      toast.error(
        `Failed to create prescription: ${error.message || "Unknown error"}`
      );
    },
  });
};
