import { useState } from "react";
import styles from "./SaveTemplateModal.module.css";
import { Loader2 } from "lucide-react";
import Modal from "../../../../../../../components/helpers/Modal/Modal";

export const SaveTemplateModal = ({
  isOpen,
  onClose,
  onSave,
  isSaving = false,
}) => {
  const [templateName, setTemplateName] = useState("");

  const handleSave = () => {
    if (!templateName.trim()) {
      return;
    }
    onSave(templateName);
  };

  return (
    <>
      {isOpen && (
        <Modal
          title="Save as Template"
          width="600px"
          onClose={onClose}
          hideCancel
        >
          <div className={styles.form}>
            <div className={`${styles.formField} form-field-controll`}>
              <div>
                <label className={styles.formLabel}>Template Name</label>
                <input
                  type="text"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  className="form-control"
                  autoFocus
                />
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                type="button"
                onClick={handleSave}
                disabled={!templateName.trim() || isSaving}
                className={styles.button}
              >
                {isSaving ? (
                  <>
                    <Loader2 className={styles.spinner} size={16} />
                    Saving...
                  </>
                ) : (
                  "Save Template"
                )}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
