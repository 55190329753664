import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

export const useAcuteMedications = (patientId) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const userName =
    useSelector((state) => state.auth.user?.name) ||
    localStorage.getItem("kinesin-name");

  return useQuery({
    queryKey: ["acuteMedications", patientId],
    queryFn: async () => {
      if (!patientId) return null;

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientmedication/getbypatientidandtyoe/${patientId}/ACUTE`
      );

      return response.data.map((item) => ({
        ...item,
        entry: userName,
      }));
    },
    enabled: !!patientId,
    onError: (error) => {
      toast.error(`Error fetching acute medications: ${error.message}`);
    },
  });
};
