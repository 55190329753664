import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";

export const usePatientData = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["patient", patientId],
    queryFn: async () => {
      if (!patientId || !tenantVal) return null;
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patient/get/${patientId}`
      );
      return response.data;
    },
    enabled: !!patientId && !!tenantVal,
    onError: (error) => {
      toast.error(`Error fetching patient data: ${error.message}`);
    },
  });
};
