import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

export const useMainService = (serviceId) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["mainService", serviceId],
    queryFn: async () => {
      if (!serviceId) return null;

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/practiceservice/get/${serviceId}`
      );
      return response.data;
    },
    enabled: !!serviceId,
    onError: (error) => {
      toast.error(`Error fetching main service: ${error.message}`);
    },
  });
};
