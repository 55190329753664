import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { ReportsNavbar } from "./ReportsNavbar/ReportsNavbar";
import { ReportTable } from "./ReportsNavbar/components/ReportTable/ReportTable";
import { reportTypeToTableTitle } from "./ReportsNavbar/utils/reportTypeToTableTitle";
import { PatientsFilters } from "./PatientsFilters/PatientsFilters";
import { PrescriptionsFilters } from "./PrescriptionsFilters/PrescriptionsFilters";
import { InvestigationsFilters } from "./InvestigationsFilters/InvestigationsFilters";
import { LettersFilters } from "./LettersFilters/LettersFilters";
import { AccountingFilters } from "./AccountingFilters/AccountingFilters";
import { AppointmentsFilters } from "./AppointmentsFilters/AppointmentsFilters";
import { MedicoLegalFilters } from "./MedicoLegalFilters/MedicoLegalFilters";
import { AuditLogFilters } from "./AuditLogFilters/AuditLogFilters";
import { ImmunistationsFilters } from "./ImmunistationsFilters/ImmunistationsFilters";
import { FiltersWrapper } from "./FiltersWrapper/FiltersWrapper";

import { fetchReports } from "./utils/fetchReports";
import { fetchColumns } from "./utils/fetchColumns";

import styles from "./Reports.module.css";

const Reports = () => {
  const [queryParams, setQueryParams] = useState("");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const { reportType } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["reports", reportType, queryParams],
    queryFn: () => fetchReports(tenantVal, reportType, queryParams),
  });

  useEffect(() => {
    setQueryParams("");
  }, [reportType]);

  console.log(queryParams);

  return (
    <div className={styles.reportsPageWrapper}>
      <ReportsNavbar />
      <FiltersWrapper filtersCount={0}>
        {!reportType && <PatientsFilters setQueryParams={setQueryParams} />}
        {reportType === "immunisations" && (
          <ImmunistationsFilters setQueryParams={setQueryParams} />
        )}
        {reportType === "prescriptions" && (
          <PrescriptionsFilters setQueryParams={setQueryParams} />
        )}
        {reportType === "investigations" && (
          <InvestigationsFilters setQueryParams={setQueryParams} />
        )}
        {reportType === "letters" && (
          <LettersFilters setQueryParams={setQueryParams} />
        )}
        {reportType === "accounting" && (
          <AccountingFilters setQueryParams={setQueryParams} />
        )}
        {reportType === "appointments" && (
          <AppointmentsFilters setQueryParams={setQueryParams} />
        )}
        {reportType === "medico-legal" && (
          <MedicoLegalFilters setQueryParams={setQueryParams} />
        )}
        {reportType === "audit-log" && (
          <AuditLogFilters setQueryParams={setQueryParams} />
        )}
      </FiltersWrapper>

      <ReportTable
        title={reportTypeToTableTitle(reportType)}
        items={data || []}
        isLoading={isLoading}
        columns={fetchColumns(reportType)}
      />
    </div>
  );
};

export default Reports;
