import React from "react";
import s from "./LongTermMedication.module.css";
import { Toaster } from "react-hot-toast";
import { useLongTermMedications } from "../../../../../../hooks/useLongTermMedications";
import { DrugsTable } from "../DrugsTable/DrugsTable";

const LongTermMedication = () => {
  const { data: medications, isLoading } = useLongTermMedications();

  return (
    <div className={s.longTermWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={s.topWrapper}>
        <h3 className={s.title}>Long term medications</h3>
      </div>
      <DrugsTable
        items={medications}
        isLoading={isLoading}
        tableName="longterm-medications"
        emptyTitle="No Long term prescriptions"
      />
    </div>
  );
};

export default LongTermMedication;
