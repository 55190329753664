import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import sortIcon from "../../../../../../assets/images/sort.svg";
import sortNewestIcon from "../../../../../../assets/images/sortNewest.svg";
import sortOldestIcon from "../../../../../../assets/images/sortOldest.svg";
import styles from "./ReportTable.module.css";

export const SortByButton = ({ selectedItem, setSelectedItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="sortBy"
        aria-controls={open ? "sortBy" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.sortByButton}
      >
        Sort by
        <img src={sortIcon} alt="sort" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={styles.menuItemWrapper}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setSelectedItem("newest-first");
          }}
          className={`${styles.menuItem} ${
            selectedItem === "newest-first" &&
            styles.sortByButtonMenuOptionActive
          }`}
        >
          <p>
            <img src={sortNewestIcon} alt="sort by date (Newest first)" />
            Date (Newest first)
          </p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setSelectedItem("oldest-first");
          }}
          className={`${styles.menuItem} ${
            selectedItem === "oldest-first" &&
            styles.sortByButtonMenuOptionActive
          }`}
        >
          <p>
            <img src={sortOldestIcon} alt="sort by date (Olderst first)" />
            Date (Oldest first)
          </p>
        </MenuItem>
      </Menu>
    </div>
  );
};
