import { Table } from "./Table/Table";
import styles from "../../PatientOverviewModal.module.css";
import { useAllergies } from "../../../../../../hooks";

export const Allergies = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const { data: all, isPending: isLoading } = useAllergies(patientId);

  const [allergies, intolerances] = (all || []).reduce(
    (acc, item) => {
      if (item.allergyType === "ALLERGY") {
        acc[0].push(item);
      } else {
        acc[1].push(item);
      }
      return acc;
    },
    [[], []]
  );

  return (
    <div>
      <h3 className={styles.tableTitle}>Intolerance</h3>
      <Table
        items={intolerances || []}
        tableName="allergies-intolerance"
        isLoading={isLoading}
      />
      <h3 className={styles.tableTitle}>Allergies</h3>
      <Table
        items={allergies || []}
        tableName="allergies-allergies"
        isLoading={isLoading}
      />
    </div>
  );
};
