import React, { useEffect, useState } from "react";
import "../../../assets/css/allied_settings.css";
import Nav from "../../../components/AfterLoginNav/Nav";
import Tab from "../../../components/SettingsTab/Tab";
import editicon from "../../../assets/images/editicon.svg";
import removicon from "../../../assets/images/removicon.svg";
import smsIcon from "../../../assets/images/viewSms.svg";
import DeleteModal from "../../../components/Modal/DeleteModal";
import TemplateModal from "../../../components/Modal/TemplateModal";
import { createAxiosInstance } from "../../../api/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import {
    NOTES_TEMPLATE_CONTROLLER, NOTES_TEMPLATE_CONTROLLER_ID, NOTES_TEMPLATE_CONTROLLER_ID_REMOVE,
    SMS_TEMPLATE_CONTROLLER,
    SMS_TEMPLATE_CONTROLLER_ID,
    SMS_TEMPLATE_CONTROLLER_ID_REMOVE,
} from "../../../api/Service";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { logoutFromHelper } from "../../../api/Helper";
import { logout } from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import { Empty, Table } from "antd";
import NoteTemplateModal from "../../../components/Modal/NoteTemplateModal";

const smsDetails = [];
const ConsultationNoteSettngs = () => {
    const [template, setTemplate] = useState([]);
    const [modalHeaderTitle, setModalHeaderTitle] = useState(""); // State for modal header title
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [selectedTemplateId, setSelectedTemplateId] = useState(null); //State for selected task id
    const [dataFetching, setDataFetching] = useState(true);

    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [dynamicDataSource, setDynamicDataSource] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let tenantVal = useSelector((state) => state.auth.user?.tenant);
    if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");

    const fetchSms = async () => {
        try {
            let ar = [];
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${NOTES_TEMPLATE_CONTROLLER}`
            );
            if (response && response.data) {
                setDataFetching(false)
                setTemplate(response.data);

                response.data.forEach((element) => {
                    ar.push(
                        {
                            id:  element.id,
                            key: element.id,
                            name: element.name,
                            note: element.note,
                            edit: (
                                <>
                                    <div className="action-icon-wrap edt_rmviconbox">
                                        <button
                                            type="submit"
                                            className="deletebtn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#addTemplate"
                                            onClick={() => handleEditTemplate(response.data,element.id)}
                                        >
                                            <img src={editicon} alt="" />
                                        </button>
                                        <button
                                            type="button"
                                            className="deletebtn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#myModal"
                                            onClick={() => handleDeleteuser(element.id)}

                                        >
                                            <img src={removicon} alt="" />
                                        </button>
                                    </div>
                                </>
                            ),
                        }
                    );
                });
                setDynamicDataSource(ar);
                setIsPageLoaded(true);
            }
        } catch (error) {
            errorHandling(error);
        }
    };

    useEffect(() => {
        fetchSms();
    }, [tenantVal]);

    const createNewTemplate = async (userData) => {
        try {
            const axiosInstance = createAxiosInstance();
            let response;
            if (selectedTemplateId) {
                const editedData = { ...userData, id: selectedTemplateId };
                response = await axiosInstance.post(
                    `${tenantVal}${NOTES_TEMPLATE_CONTROLLER}`,
                    editedData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                toast.success("Template updated successfully.");
            } else {
                console.log("User template consult")
                console.log(userData)
                response = await axiosInstance.post(
                    `${tenantVal}${NOTES_TEMPLATE_CONTROLLER}`,
                    userData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                toast.success("Template saved successfully.");
            }
            if (selectedTemplateId) {
                const updatedTasks = template.map((item) =>
                    item.id === selectedTemplateId ? { ...item, ...response.data } : item
                );
                setTemplate(updatedTasks);
            } else {
                setTemplate((prevService) => [...prevService, response.data]);
            }
            fetchSms();
        } catch (error) {
            errorHandling(error);
        }
    };

    const handleAddTemplate = () => {
        setSelectedTemplate(null);
        setSelectedTemplateId(null);
        setModalHeaderTitle("Add New Consultation Note Template");
    };

    //Save template handler//
    const handleSaveTemplate = (newTemplate) => {
        let updatedStatus = newTemplate.status; // Declare a variable to hold the updated status
        if (selectedTemplateId) {
            // Edit existing template
            const updatedTasks = template.map((item) =>
                item.id === selectedTemplateId
                    ? { ...newTemplate, status: updatedStatus }
                    : item
            );
            setTemplate(updatedTasks);
        } else {
            // Add new template
            const updatedTemplate = [...template, newTemplate];
            setTemplate(updatedTemplate);
        }
    };
    //Save template handler//

    // Edit template handler//
    const handleEditTemplate = async (datas, id) => {
        const selectedTemplate = datas.find((item) => item.id === id);
        if (selectedTemplate) {
            setSelectedTemplateId(id);
            setSelectedTemplate(selectedTemplate);
            setModalHeaderTitle("Edit Consultation Note Template");
            try {
                const axiosInstance = createAxiosInstance();
                const url = `${tenantVal}/${NOTES_TEMPLATE_CONTROLLER_ID}${id}`;
                const response = await axiosInstance.get(url);
            } catch (error) {
                errorHandling(error);
            }
        }
    };
    // Edit template handler//

    // For delete template//
    const handleDeleteuser = (id) => {
        setSelectedTemplateId(id);
    };
    const deleteTemplate = async () => {
        // Filter the template array to remove the selected template
        try {
            const axiosInstance = createAxiosInstance();
            if (selectedTemplateId) {
                const url = `${tenantVal}${NOTES_TEMPLATE_CONTROLLER_ID_REMOVE}`;
                await axiosInstance.delete(url, {
                    data: {
                        id: selectedTemplateId,
                        tenant: tenantVal,
                    },
                });
                const updatedTemplate = template.filter(
                    (item) => item.id !== selectedTemplateId
                );
                // Update the template state with the updatedTemplates array
                setTemplate(updatedTemplate);
                toast.success("Template deleted successfully.");
            }
        } catch (error) {
            errorHandling(error);
        }
        fetchSms();
    };

    const errorHandling = (error) => {
        console.log(error);
        toast.error(error.message);
        if (error && error.response && error.response.status) {
            if (error.response.status == 403) {
                logoutFromHelper();
                dispatch(logout());
                dispatch(removeuser());
                navigate("/logout");
            }
        }
    };
    const [filterTable, setFilterTable] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.length - b.name.length,
            sortDirections: ["ascend", "descend"],
            onFilter: (value, record) => record.name.startsWith(value),
            filterSearch: true,
        },

        {
            title: "Template Text",
            dataIndex: "note",
            key: "note",
            sorter: (a, b) => a.note.length - b.note.length,
            sortDirections: ["ascend", "descend"],
            onFilter: (value, record) => record.note.startsWith(value),
            filterSearch: true,
        },
        {
            title: "",
            dataIndex: "edit",
            key: "edit",
        },
    ];
    return (
        <div>
            {/* <Nav /> */}
            <Toaster position="top-center" reverseOrder={false} />
            <div className="dashBcontbody">

                <div className="signup_threebox practice_detailssect smsthreebox mt-0 table-header-wrap">
                    <Tab />

                    <div
                        className="tab-content ptdetalistabcont user_detailsboxtab"
                        id="pills-tabContent"
                    >
                        <div className="show active">
                            <div className="addpatientlist">
                                <div className="user_detailsbox">
                                    <div className="tab-content smssetab">
                                        <div
                                            className="tab-pane active"
                                            id="home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >
                                            <div className="custom-table-wrapper sms-table">

                                                <div className="patientsearchbar">
                                                    <div className="btnxscenter ml-auto" style={{float: 'right', marginBottom: '10px'}}>
                                                        <button
                                                            className="custom_btn addform_btn"
                                                            type="button"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#addTemplate"
                                                            onClick={handleAddTemplate}
                                                        >
                                                            <i
                                                                className="fa-solid fa-plus"
                                                                style={{marginRight: "7px"}}
                                                            ></i>{" "}
                                                            Add New Note Template
                                                        </button>
                                                    </div>
                                                </div>

                                                <Table
                                                    dataSource={filterTable && filterTable.length > 0 ? filterTable : dynamicDataSource}
                                                    columns={columns}
                                                    locale={{
                                                        emptyText: isPageLoaded ? (
                                                                <Empty description='No Records Found'/>
                                                            ) :
                                                            <p></p>
                                                    }}
                                                    pagination={tableParams.pagination}
                                                    onChange={handleTableChange}
                                                />
                                            </div>
                                            {/* Table End */}
                                        </div>

                                        <div
                                            className="tab-pane"
                                            id="profile"
                                            role="tabpanel"
                                            aria-labelledby="profile-tab"
                                        >
                                            <h1>Consultation notes templates</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal deleteService={deleteTemplate} context="smsTemplate" />
            <NoteTemplateModal
                modalHeaderTitle={modalHeaderTitle}
                handleSaveTemplate={handleSaveTemplate}
                createNewTemplate={createNewTemplate}
                editTemplateId={selectedTemplateId}
                template={template}
            />
        </div>
    );
};

export default ConsultationNoteSettngs;
