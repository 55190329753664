import { useState } from "react";
import dayjs from "dayjs";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import style from "./AddPrescriptionForm.module.css";

import Modal from "../helpers/Modal/Modal";
import { FormField } from "../form/FormField/FormField";
import moment from "moment";
import { SelectDrug } from "./SelectDrug/SelectDrug";
import { Trash } from "lucide-react";
import { useCreatePrescription } from "../../hooks/useCreatePrescription";
import { medicineSchema } from "./utils";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

const formSchema = z.object({
  medicines: z.array(medicineSchema),
  instructions: z.string(),
});

export const AddPrescriptionForm = ({
  isDisabled,
  buttonClassName,
  buttonIcon,
  consultationId,
}) => {
  const queryClient = useQueryClient();
  const today = dayjs(new Date().setMinutes(0));
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const createPrescription = useCreatePrescription();

  const getDefaultMedicine = () => ({
    dosage: "",
    form: "",
    startDate: moment(new Date(today)).format("DD-MM-YYYY"),
    finishDay: false,
    duration: "",
    durationFormat: "",
    usageType: "",
    repeat: "",
    paymentType: "",
    isManual: false,
    tradeName: "",
    manufacturer: "",
    packSize: "",
    medicineId: "",
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      medicines: [getDefaultMedicine()],
      instructions: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "medicines",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = form.getValues();

    const prescriptionData = {
      note: values.instructions,
      patientId: patientId,
      datePrescribed: new Date().toISOString(),
      tenant: tenantVal,
      medications: values.medicines.map((item) => {
        const base = {
          consultationId: consultationId,
          dosage: item.dosage,
          form: item.form,
          startDate: moment(item.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          route: item.route,
          duration: item.duration,
          durationFormat: item.durationFormat,
          frequency: item.frequency,
          acuteLongTerm: item.usageType,
          repeat: Number(item.repeat),
          patientId: patientId,
        };

        if (item.isManual) {
          return {
            ...base,
            tradeName: item.tradeName,
            manufacturer: item.manufacturer,
            packSize: item.packSize,
          };
        }

        return { ...base, medicationId: item.medicineId };
      }),
    };

    try {
      await createPrescription.mutateAsync(prescriptionData);
      setIsModalOpen(false);
      form.reset();
      queryClient.invalidateQueries();
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <>
      <button
        className={`${style.openModalButton} ${buttonClassName}`}
        type="button"
        disabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
      >
        Add Prescription {buttonIcon}
      </button>
      {isModalOpen && (
        <Modal
          title="Add Prescription"
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="1100px"
          maxHeight="90vh"
        >
          <FormProvider {...form}>
            <form className={style.addLetterForm} onSubmit={handleSubmit}>
              <div className={style.medicineSectionWrapper}>
                {fields.map((field, index) => (
                  <div key={field.id} className={style.medicineSection}>
                    <div className={style.medicineSectionHeader}>
                      <p className={style.addLetterFormLabel}>Medicine</p>
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className={style.removeButton}
                          aria-label="Remove medicine"
                        >
                          <Trash width={18} height={18} />
                        </button>
                      )}
                    </div>

                    <div className={style.drugSection}>
                      <SelectDrug index={index} form={form} />
                    </div>

                    <div className={style.inputGroupGrid}>
                      <FormField name={`medicines.${index}.dosage`}>
                        <label className={style.addLetterFormLabel}>
                          Dosage
                        </label>
                        <input
                          className="form-control"
                          {...form.register(`medicines.${index}.dosage`)}
                        />
                      </FormField>

                      <FormField name={`medicines.${index}.form`}>
                        <label className={style.addLetterFormLabel}>Form</label>
                        <input
                          className="form-control"
                          {...form.register(`medicines.${index}.form`)}
                        />
                      </FormField>

                      <FormField name={`medicines.${index}.startDate`}>
                        <label className={style.addLetterFormLabel}>
                          Start date
                        </label>
                        <div className={style.addPregnancyDatePickerWrapper}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD-MM-YYYY"
                              defaultValue={today}
                              sx={{ width: "100%", height: "36px" }}
                              onChange={(newValue) => {
                                form.setValue(
                                  `medicines.${index}.startDate`,
                                  moment(new Date(dayjs(newValue))).format(
                                    "DD-MM-YYYY"
                                  )
                                );
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </FormField>
                      <FormField name={`medicines.${index}.endDate`}>
                        <label className={style.addLetterFormLabel}>
                          Route
                        </label>
                        <div className={style.addPregnancyDatePickerWrapper}>
                          <select
                            className="form-select form-control custom-select"
                            {...form.register(`medicines.${index}.route`)}
                          >
                            <option value=""></option>
                            <option value="Aural">Aural</option>
                            <option value="Inh">Inhalation</option>
                            <option value="IM">Intramuscular</option>
                            <option value="Intranasal">Intranasal</option>
                            <option value="IV">Intravenous</option>
                            <option value="PO">PO</option>
                            <option value="PR">PR</option>
                            <option value="PV">PV</option>
                            <option value="SC">Subcutaneous</option>
                            <option value="Sublingual">Sublingual</option>
                            <option value="Top">Topical</option>
                          </select>
                        </div>
                      </FormField>
                      <FormField name={`medicines.${index}.duration`}>
                        <label className={style.addLetterFormLabel}>
                          Duration (optional)
                        </label>
                        <input
                          className="form-control"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          {...form.register(`medicines.${index}.duration`, {
                            onChange: (e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            },
                          })}
                        />
                      </FormField>

                      <FormField name={`medicines.${index}.durationFormat`}>
                        <label className={style.addLetterFormLabel}>
                          Duration format (optional)
                        </label>
                        <select
                          className="form-select form-control custom-select"
                          {...form.register(
                            `medicines.${index}.durationFormat`
                          )}
                        >
                          <option value="days">Days</option>
                          <option value="months">Months</option>
                        </select>
                      </FormField>

                      <FormField name={`medicines.${index}.endDate`}>
                        <label className={style.addLetterFormLabel}>
                          Frequency
                        </label>
                        <div className={style.addPregnancyDatePickerWrapper}>
                          <input
                            className="form-control"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            {...form.register(`medicines.${index}.frequency`, {
                              onChange: (e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              },
                            })}
                          />
                        </div>
                      </FormField>
                      <FormField name={`medicines.${index}.usageType`}>
                        <label className={style.addLetterFormLabel}>
                          Acute / long term
                        </label>
                        <select
                          className="form-select form-control custom-select"
                          {...form.register(`medicines.${index}.usageType`)}
                        >
                          <option value="ACUTE">Acute</option>
                          <option value="LONGTERM">Long term</option>
                        </select>
                      </FormField>

                      <FormField name={`medicines.${index}.repeat`}>
                        <label className={style.addLetterFormLabel}>
                          Repeat (optional)
                        </label>
                        <input
                          className="form-control"
                          placeholder="How many times?"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          {...form.register(`medicines.${index}.repeat`, {
                            onChange: (e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            },
                          })}
                        />
                      </FormField>
                    </div>
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={() => append(getDefaultMedicine())}
                className={style.addAnotherMedicine}
              >
                Add another medicine +
              </button>
              <FormField name="instructions">
                <label className={style.addLetterFormLabel}>
                  Instructions (optional)
                </label>
                <textarea
                  className="form-control textareaform-control"
                  style={{ minHeight: "126px" }}
                  {...form.register("instructions")}
                />
              </FormField>
              <div className={style.bottomSection}>
                <label className={style.formCheckboxLabel}></label>
                <div>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className={style.openModalButton}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={createPrescription.isPending}
                    className={style.sendButton}
                  >
                    {createPrescription.isPending ? "Submitting..." : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </Modal>
      )}
    </>
  );
};
