import styles from "../../PatientOverviewModal.module.css";
import { Table } from "./Table/Table";
import { useMedications } from "../../../../../../hooks";

export const Medications = () => {
  const patientId = localStorage.getItem("kinesin-patientId");

  const { data: medications, isLoading } = useMedications(patientId);

  return (
    <div className={styles.medicalHistoryWrapper}>
      <Table
        items={medications}
        tableName="medications"
        isLoading={isLoading}
      />
    </div>
  );
};
