import React from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";

import s from "./PatientMedication.module.css";
import AllergiesIntolerance from "../../PatientDetailGp/components/AllergiesIntolerance/AllergiesIntolerance";
import LongTermMedication from "./components/LongTermMedication/LongTermMedication.jsx";
import { MedicalHistoryCard } from "../../../../components/PatientDetailCardWithOverview/components/PatientOverviewModal/components/MedicalHistory/MedicalHistoryCard.jsx";
import PatientDetailCardWithOverview from "../../../../components/PatientDetailCardWithOverview/PatientDetailCardWithOverview.jsx";
import { Prescriptions } from "./components/Prescriptions/Prescriptions.jsx";
import AcuteMedication from "./components/ActuteMedication/AcuteMedication.jsx";

const PatientMedication = () => {
  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            <>
              <PatientDetailCardWithOverview />
              <AllergiesIntolerance />
              <MedicalHistoryCard />
            </>
          </div>
          <div className={s.secondColumnWrapper}>
            <Prescriptions />
            <LongTermMedication />
            <AcuteMedication />
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientMedication;
