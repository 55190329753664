import { useMemo, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import srcicon from "../../../../../../assets/images/srcicon.svg";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import moment from "moment";
import styles from "./ReportTable.module.css";
import { Loader } from "../../../../../../components/Loader/Loader";
import { SortByButton } from "./SortByButton";
import { GripVertical } from "lucide-react";

export const ReportTable = ({ title, items, isLoading, columns }) => {
  const [columnResizing, setColumnResizing] = useState({});
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("newest-first");

  const filteredAndSortedItems = useMemo(() => {
    const filtered = search
      ? items.filter((item) => {
          const searchStr = `${item.firstName} ${item.lastName}`.toLowerCase();
          return searchStr.includes(search.toLowerCase());
        })
      : items;

    return [...filtered].sort((a, b) => {
      if (!a.dateOfConsultation && !b.dateOfConsultation) return 0;

      if (!a.dateOfConsultation) return 1;
      if (!b.dateOfConsultation) return -1;

      const dateA = new Date(a.dateOfConsultation);
      const dateB = new Date(b.dateOfConsultation);

      return sorting === "newest-first" ? dateB - dateA : dateA - dateB;
    });
  }, [items, search, sorting]);


  const table = useReactTable({
    data: filteredAndSortedItems,
    columns,
    state: {
      columnResizing,
    },
    onColumnResizingChange: setColumnResizing,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    defaultColumn: {
      minSize: 25,
      maxSize: 1000,
      size: 150,
    },
  });

  return (
    <Paper className={styles.reportTablePaper}>
      <div className={styles.reportTableWrapper}>
        <h3>{title}</h3>
        <div className={styles.reportTable}>
          <div className={styles.reportTableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton selectedItem={sorting} setSelectedItem={setSorting} />
          </div>

          {isLoading && <Loader>Loading reports...</Loader>}

          {table.getRowModel().rows.length <= 0 && !isLoading && (
            <h4 className={styles.reportNoReportsWarning}>
              There are no reports matching current filters.
            </h4>
          )}

          {table.getRowModel().rows.length > 0 && !isLoading && (
            <TableContainer className={styles.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow
                      key={headerGroup.id}
                      className={styles.tableHeaderRow}
                    >
                      {headerGroup.headers.map((header) => (
                        <TableCell
                          key={header.id}
                          className={styles.tableHeaderCell}
                          style={{
                            width: header.getSize(),
                            position: "relative",
                            overflow: "visible",
                            background: "transparent",
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanResize() && (
                            <div
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={`${styles.resizer} ${
                                header.column.getIsResizing()
                                  ? styles.isResizing
                                  : ""
                              }`}
                            >
                              <GripVertical className={styles.resizerIcon} />
                            </div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} className={styles.tableRow}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className={styles.tableCell}
                          style={{
                            width: cell.column.getSize(),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </Paper>
  );
};
