import { PlusIcon } from "lucide-react";
import { AddPrescriptionForm } from "../../../../../../components/AddPrescriptionForm/AddPrescriptionForm";
import styles from "./Prescriptions.module.css";
import { Table } from "./Table/Table";
import { usePatientPrescriptions } from "../../../../../../hooks/usePatientPrescriptions";

export const Prescriptions = () => {
  const { data: prescriptions, isLoading } = usePatientPrescriptions();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Prescriptions</h2>
        <AddPrescriptionForm
          buttonClassName={styles.addPrescriptionButton}
          buttonIcon={<PlusIcon width={18} height={18} />}
        />
      </div>
      <Table
        items={prescriptions ?? []}
        tableName="prescriptionsTable"
        isLoading={isLoading}
      />
    </div>
  );
};
