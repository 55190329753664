import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

export const useMedicalHistory = (patientId) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["medicalHistory", patientId],
    queryFn: async () => {
      if (!patientId) return null;
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientmedicalhistory/getbypatientid/${patientId}`
      );
      return response.data;
    },
    enabled: !!patientId,
    onError: (error) => {
      toast.error(`Error fetching medical history: ${error.message}`);
    },
  });
};
