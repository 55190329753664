// Importing helper modules
import { useCallback, useMemo, useRef, useState, useEffect } from "react";

// Importing core components
import QuillEditor from "react-quill";

// Importing styles
import "react-quill/dist/quill.snow.css";
import styles from "./RichEditor.css";

const RichEditor = ({editorValue, onChange}) => {
    // Editor state
    const [value, setValue] = useState("");
    console.log(" data value = " + editorValue);

    useEffect(() => {
        console.log("SETTING   data value = " + editorValue);

        setValue(editorValue);
    }, [])

    // Editor ref
    const quill = useRef();

    // Handler to handle button clicked
    function textAreaChange(text) {
        setValue(text)
        if(onChange)
            onChange(text);
    }

    const imageHandler = useCallback(() => {
        // Create an input element of type 'file'
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // When a file is selected
        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            // Read the selected file as a data URL
            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = quill.current.getEditor();

                // Get the current selection range and insert the image at that index
                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
            };

            reader.readAsDataURL(file);
        };
    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        [imageHandler]
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];

    return (
        <div className={styles.wrapper}>
            <QuillEditor
                ref={(el) => (quill.current = el)}
                className={styles.editor}
                theme="snow"
                value={value}
                formats={formats}
                modules={modules}
                onChange={(value) => textAreaChange(value)}
            />
        </div>
    );
};


export default RichEditor;