import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import sortIcon from "../../../../../../../assets/images/sort.svg";
import { ArrowDown, ArrowUp } from "lucide-react";
import styles from "./Table.module.css";

export const SortByButton = ({ selectedItem, setSelectedItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="sortBy"
        aria-controls={open ? "sortBy" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.sortByButton}
      >
        Sort by
        <img src={sortIcon} alt="sort" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={styles.menuItemWrapper}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setSelectedItem("a-to-z");
          }}
          className={`${styles.menuItem} ${
            selectedItem === "a-to-z" && styles.sortByButtonMenuOptionActive
          }`}
        >
          <button>
            <ArrowDown size={20} />
            Alphabetically (A to Z)
          </button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setSelectedItem("z-to-a");
          }}
          className={`${styles.menuItem} ${
            selectedItem === "z-to-a" && styles.sortByButtonMenuOptionActive
          }`}
        >
          <button>
            <ArrowUp size={20} />
            Alphabetically (Z to A)
          </button>
        </MenuItem>
      </Menu>
    </div>
  );
};
