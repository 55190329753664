import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { SVGIcons } from "../components/Data/SVGIcons.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import moment from "moment";
import Calendar from "react-calendar";

const BREAKPOINTS = {
  320: { slidesPerView: 2 },
  375: { slidesPerView: 2.5 },
  414: { slidesPerView: 3.5 },
  576: { slidesPerView: 5 },
  768: { slidesPerView: 6 },
  992: { slidesPerView: 4 },
  1200: { slidesPerView: 5 },
  1800: { slidesPerView: 6 },
};

const generateYearOptions = (currentYear) => {
  const previousYears = Array.from(
    { length: 10 },
    (_, i) => currentYear - i
  ).reverse();
  const futureYears = Array.from({ length: 10 }, (_, i) => currentYear + i + 1);
  return [...previousYears, currentYear, ...futureYears].filter(
    (year, index, self) => self.indexOf(year) === index
  );
};

const generateDaysArray = (year, month) => {
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  return Array.from({ length: daysInMonth }, (_, index) => ({
    id: index + 1,
    Week: moment(`${year}-${month}-${index + 1}`, "YYYY-MM-D").format("ddd"),
    Num: index + 1,
  }));
};

const HorizontalCalendarNew1 = ({
  selectedDate,
  selectedMonth,
  selectedYear,
  setSelectedDate,
  setSelectedMonth,
  setSelectedYear,
}) => {
  const [showReactCalendar, setShowReactCalendar] = useState(false);
  const [data, setData] = useState([]);
  const swiperRef = useRef(null);

  const toggleCalendarView = () => {
    const body = document.querySelector("html");
    setShowReactCalendar((prev) => !prev);
    body.classList.toggle("show-toggle-menu");
  };

  const handleDateSelection = (newDate) => {
    const dateObject = moment(newDate);
    setSelectedDate(dateObject.date());
    setSelectedMonth(dateObject.month() + 1);
    setSelectedYear(dateObject.year());
    toggleCalendarView();
  };

  const handleSwiperNavigation = (direction) => (e) => {
    e.preventDefault();
    const swiperInstance = swiperRef.current?.swiper;
    if (!swiperInstance) return;

    const isAtBoundary =
      direction === "next" ? swiperInstance.isEnd : swiperInstance.isBeginning;
    const slideFunction =
      direction === "next"
        ? swiperInstance.slideNext
        : swiperInstance.slidePrev;

    if (isAtBoundary) {
      const isYearChange =
        (direction === "next" && selectedMonth === 12) ||
        (direction === "prev" && selectedMonth === 1);

      if (isYearChange) {
        setSelectedYear((prev) => prev + (direction === "next" ? 1 : -1));
        setSelectedMonth(direction === "next" ? 1 : 12);
      } else {
        setSelectedMonth((prev) => prev + (direction === "next" ? 1 : -1));
      }
      setSelectedDate(1);
      swiperInstance.slideTo(0);
    } else {
      slideFunction.call(swiperInstance);
    }
  };

  useEffect(() => {
    const daysArray = generateDaysArray(selectedYear, selectedMonth);
    setData(daysArray);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper;
    if (swiperInstance) {
      swiperInstance.slideTo(selectedDate - 1);
    }
  }, [selectedDate, showReactCalendar]);

  const renderCalendarToggle = () => (
    <div className="hamburger_menu" onClick={toggleCalendarView}>
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <div key={i} className="toggle_line" />
        ))}
    </div>
  );

  const renderDateSelectors = () => (
    <div className="d-flex choose-date-time-select-wrap w-100">
      <Form.Select
        className="choose-date-time-select w-50 no-max-width"
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
      >
        {moment.months().map((month, index) => (
          <option key={index} value={index + 1}>
            {month}
          </option>
        ))}
      </Form.Select>
      <Form.Select
        className="choose-date-time-select w-50 no-max-width"
        value={selectedYear}
        onChange={(e) => setSelectedYear(parseInt(e.target.value))}
      >
        {generateYearOptions(moment().year()).map((year, index) => (
          <option key={index} value={year}>
            {year}
          </option>
        ))}
      </Form.Select>
    </div>
  );

  const renderDaySlide = (day) => (
    <SwiperSlide
      key={day.id}
      className={selectedDate === day.Num ? "active" : ""}
      onClick={() => setSelectedDate(day.Num)}
    >
      <div
        className="d-flex flex-column align-items-center justify-content-center date-time-card-slider"
        style={{
          backgroundColor:
            selectedDate > day.Num ? "rgb(242, 242, 250)" : undefined,
          cursor: "pointer",
        }}
      >
        <span className="d-inline-block week fw-500">{day.Week}</span>
        <span className="d-inline-block number h3 small mb-0">{day.Num}</span>
      </div>
    </SwiperSlide>
  );

  return (
    <div className="single-calendar-wrap">
      {showReactCalendar ? (
        <>
          {renderCalendarToggle()}
          <Calendar
            onChange={handleDateSelection}
            value={new Date(selectedYear, selectedMonth - 1, selectedDate)}
          />
        </>
      ) : (
        <Form className="practice-details-form-step">
          <div className="d-flex align-items-center btn-wrap">
            {renderCalendarToggle()}
            <div className="calendar-header-wrap d-flex align-items-center w-100 justify-content-between">
              {renderDateSelectors()}
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <button
              type="button"
              className="swiper-button-prev swiper-btn"
              onClick={handleSwiperNavigation("prev")}
            >
              {SVGIcons.SliderLeftArrow}
            </button>
            <div className="calander-slider">
              <Swiper
                ref={swiperRef}
                navigation={false}
                clickable={true}
                spaceBetween={10}
                slidesPerView={6}
                allowTouchMove={true}
                breakpoints={BREAKPOINTS}
              >
                {data.map(renderDaySlide)}
              </Swiper>
            </div>
            <button
              type="button"
              className="swiper-button-next swiper-btn"
              onClick={handleSwiperNavigation("next")}
            >
              {SVGIcons.SliderRightArrow}
            </button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default HorizontalCalendarNew1;
